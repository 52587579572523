import React, { useState, useEffect } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment';
const TableDemo = () => {
    const [loading1, setLoading1] = useState(true);
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [products, setProducts] = useState([]);

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });

        setGlobalFilterValue1("");
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        console.log(value);
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;
        console.log(_filters1);
        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    };
    const clearFilter1 = () => {
        initFilters1();
    };
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
                <div className="table-header-container">All Registered Sims</div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const header1 = renderHeader1();

    const statusBodyTemplate5 = (rowData) => {
        return <p>{rowData.failure_reason?rowData.failure_reason :'-' }</p>;
    };


    const statusBodyTemplate4 = (rowData) => {
        return <p>{moment(rowData.full_date).format('MMMM Do YYYY ,h:mm:ss a')}</p>;
    }; 
 
    useEffect(() => {
        const getDatz = async () => {
            const statz = await axios.get("https://mtn-services.verxid.site/viewAllSim");
            console.log(statz.data.mtn);

            setProducts(statz.data.mtn.reverse());
            setLoading1(false);
        };
        getDatz();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        initFilters1();
    }, []);
    const statusBodyTemplate2 = (rowData) => {
        return (
            <Link className={`product-badge font-bold`} to={`/single-sim/${rowData.id}`}>
                VIEW
            </Link>
        );
    };

    const statusBodyTemplate3 = (rowData) => {
        return <p>{rowData.sync === "0" ? "Syncing" : rowData.sync === "1" ? "Submitted" : rowData.sync === "2" ? "Failed" : rowData.sync === "3" ? "Activated" : ""}</p>;
    };

    // const header = ;

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <DataTable
                        value={products}
                        // expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        loading={loading1}
                        responsiveLayout="scroll"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[5, 10, 50]}
                        // header={header}
                        header={header1}
                        filters={filters1}
                    >
                        <Column field="msisdn" header="msisdn"></Column>
                        <Column field="sim_serial" header="sim_serial"></Column>
                        <Column field="alt_phone" header="Alt-phone"></Column>
                        {/* <Column field="mother_maiden" header="Mother's Name" sortable></Column> */}
                        <Column field="" header="Status" body={statusBodyTemplate3} />
                        <Column field="email" header="Email"></Column>
                        <Column field="processing_number" header="Processing_number"></Column>
                        <Column field="deviceid" header="Device id"></Column>
                        <Column field="devicename" header="Device name"></Column>
                        {/* <Column field="full_date" header="Date"></Column> */}
                        {/* <Column field="place_birth" header="Place Of Birth"></Column> */}
                        <Column field="agentemail" header="Agent Email"></Column>
                        <Column field="" header="Date" body={statusBodyTemplate4} />
                        
                
                        <Column field="" header="Failure Reason" body={statusBodyTemplate5} />
                        <Column field="id" header="Action" body={statusBodyTemplate2} />

                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default TableDemo;
