import React,
{useEffect,useState,useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import {Chip} from 'primereact/chip';
import axios from "axios";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
// import { Password } from 'primereact/password';
import { useSelector } from 'react-redux';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import generator from "generate-password";
// eslint-disable-next-line
import userSlice from '../redux/userSlice';
const SingleUser = () => {
const {id} = useParams();
const { userInfo} = useSelector((state) => state.user);
const [sing, setSing] = useState({});
const [loading1, setLoading1] = useState(false);
const [viewSwap, setViewSwap] = useState(false);
const [loadingRegz, setLoadingRegz] = useState(false);
const [loadingSwap, setLoadingSwap] = useState(false);
const [loadingApprove, setLoadingApprove] = useState(false);
const [loadingPurge, setLoadingPurge] = useState(false);
const [isLoadingReset, setLoadingReset] = useState(false);
const [loadingBlack, setLoadingBlack] = useState(false);
const [loadingValid, setLoadingValid] = useState(false);
const [groups,setGroups] = useState([]);
const [groupId,setGroupId]=useState('');
const [groupIdAns,setGroupIdAns]=useState('');
const [loadingUpd, setLoadingUpd] = useState(false);
const [displayResponsive, setDisplayResponsive] = useState(false);
const [password,setPassword] = useState('');
const [confirmPassword,setConfirmPassword] = useState('');

const [viewReg,setViewReg] = useState(false);
const [regz,setRegz] = useState([]);
const [swapz,setSwapz] = useState([]);
const [filters1, setFilters1] = useState(null);
const [globalFilterValue1, setGlobalFilterValue1] = useState(""); 
const clearFilter1 = () => {
    initFilters1();
};

const initFilters1 = () => {
    setFilters1({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'type': { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    setGlobalFilterValue1("");
};


const [filters2, setFilters2] = useState(null);
const [globalFilterValue2, setGlobalFilterValue2] = useState(""); 
const clearFilter2 = () => {
    initFilters2();
};

const initFilters2 = () => {
    setFilters2({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'swap_type': { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    setGlobalFilterValue2("");
};


const statuses = [
    'fx_new', 'cx_new'
];

const statuses2 = [
    'sim_c', 'sim_u'
];
const statusRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select a Type" className="p-column-filter" showClear />;
}

const statusRowFilterTemplate2 = (options) => {
    return <Dropdown value={options.value} options={statuses2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select a swap Type" className="p-column-filter" showClear />;
}



const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
}
     

const generatePassword = () => {
    const pwd = generator.generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
    });
    setPassword(pwd);
    setConfirmPassword(pwd);
  }


const getRegz=async ()=>{
   
        setLoadingRegz(true);
        try {
    var config = {
        method: 'get',
        url: `https://mtn-services.verxid.site/getDeviceStat?agentemail=${sing?.email}`,
      };
          const res = await axios(config);

    setLoadingRegz(false);
    const listz = res.data.mtn.lastest;
    const newLz = listz.map((item,i)=>{
return {...item,firstname:item.firstname.toLowerCase(),lastname:item.lastname.toLowerCase()}
    })
    setRegz(newLz);  

    // console.log(newLz)

    setViewReg(true);
        } 
        catch (error) { 
            setLoadingRegz(false);
         console.log(error)
        }  
    
    }

    const getRegz2=async ()=>{
   
        setLoadingSwap(true);
        try {
    var config = {
        method: 'get',
        url: `https://mtn-services.verxid.site/getSwapStat?agentemail=${sing?.email}`,
      };
          const res = await axios(config);

          setLoadingSwap(false);
          console.log(res)
    const listz = res.data.mtn.lastest;

    setSwapz(listz);  
    setViewSwap(true);
        } 
        catch (error) { 
            setLoadingSwap(false);
         console.log(error)
        }  
    
    }
    

const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    console.log(value);
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    console.log(_filters1);
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
};

const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    // console.log(value);
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;
    // console.log(_filters1);
    setFilters2(_filters2);
    setGlobalFilterValue2(value);
};
const renderHeader1 = () => {
    return (
        <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
            {/* <div className="table-header-container">All Onboarded Users</div> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
            </span>
        </div>
    );
};
const header1 = renderHeader1();


const renderHeader2 = () => {
    return (
        <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter2} />
            {/* <div className="table-header-container">All Onboarded Users</div> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
            </span>
        </div>
    );
};
const header2 = renderHeader2();

const agentStatus = (rowData) => {
    let stat;
    if (rowData.sync === "1") {
        stat = "Submitted";
    } else if (rowData.sync === "0") {
        stat = "Pending";
    } else if (rowData.sync === "2") {
        stat = "failed";
    }
    else if (rowData.sync === "3") {
        stat = "Activated";
    }

    return stat;
};

const onShow = () => {
    setDisplayResponsive(true);

}

const onHide = () => {
    setDisplayResponsive(false);
}


const renderFooter = () => {
    return (
        
            <Button label="Close" icon="pi pi-times" onClick={onHide} className="p-button-text" />
           
    );
}



const toast = useRef(null);



const approval=async()=>{
    setLoadingApprove(true);
    try {
        var formdata = new FormData();
      formdata.append('id', id);
var config = {
    method: 'post',
    url: `https://mtn-services.verxid.site/approve-agent`,
    data : formdata
  };
      const res = await axios(config);
    //   const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
      
// console.log(res.data);
const stat = res.data.mtn.status;
    // console.log(stat)
      if(stat === '1'){
          setLoadingApprove(false);
          window.location.href=`/single-user/${id}`
      }

      else{
        toast.current?.show({severity:'error', summary: 'Agent not approved.', detail:res.mtn.message, life: 2500});
        setLoadingApprove(false)
      }
           
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'Agent not approved.', detail:'please try again', life: 2500});
        setLoadingApprove(false);
     console.log(error)

    }

    
}


const blacked=async()=>{
    setLoadingBlack(true);
    try {
        var formdata = new FormData();
      formdata.append('id', id);
var config = {
    method: 'post',
    url: `https://mtn-services.verxid.site/blacklist-agent`,
    data : formdata
  };
      const res = await axios(config);
    //   const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
      
// console.log(res.data);
      const stat = res.data.mtn.status;
      console.log(stat)
      if(stat === '1'){ 
          setLoadingBlack(false)
          window.location.href=`/single-user/${id}`
      }
      else{
        toast.current?.show({severity:'error', summary: 'Agent not blacklisted.', detail:res.mtn.message, life: 2500});
        setLoadingBlack(false)
      }
           
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'Agent not blacklisted.', detail:'please try again', life: 2500});
        setLoadingBlack(false);
     console.log(error)

    }

    
}




const valid=async()=>{
    setLoadingValid(true);
    try {
        var formdata = new FormData();
      formdata.append('id', id);
var config = {
    method: 'post',
    url: `https://mtn-services.verxid.site/invalidate-onboarding`,
    data : formdata
  };
      const res = await axios(config);
    //   const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
      
// console.log(res.data);
// console.log(res)
      const stat = res.data.mtn.status;
      console.log(stat)
      if(stat === '1'){ 
          setLoadingValid(false)
          window.location.href=`/single-user/${id}`
      }
      else{
        toast.current?.show({severity:'error', summary: 'Agent not invalidated.', detail:res.mtn.message, life: 2500});
        setLoadingValid(false)
      }
           
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'Agent not invalidated.', detail:'please try again', life: 2500});
        setLoadingValid(false)
     console.log(error)

    }

    
}


const onRoleChange = (e) => {
    console.log(e.value)
    setGroupId(e.value);
    setGroupIdAns(e.value.id);
}


const updateAgent=async()=>{
    setLoadingUpd(true);
try {

    var formdata = new FormData();
        
        formdata.append('id', id);
         formdata.append('new_group_id', groupIdAns);
         
 var config = {
     method: 'post',
    url: 'https://mtn-services.verxid.site/update-agent-group',
     data : formdata
   };
          const res = await axios(config);  
          if(res.data.mtn.status === 1){
            
            toast.current?.show({severity:'success', summary: 'User update successful.', detail:'', life: 2500});
            setLoadingUpd(false);
            window.location.href=`/single-user/${id}`;
          }
          else{
            toast.current?.show({severity:'error', summary: 'User Update failed.', detail:'please try again', life: 2500});
            setLoadingUpd(false);
          }
    
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'User Update failed.', detail:'please try again', life: 2500});
            setLoadingUpd(false);
         console.log(error)
        }
   

}

const handleReset=async()=>{
setLoadingReset(true);
if(password === confirmPassword){
try {

    var formdata = new FormData();
        
        formdata.append('id', id);
         formdata.append('password', password);
         formdata.append('confirm', confirmPassword);
         
 var config = {
     method: 'post',
    url: 'https://mtn-services.verxid.site/update-password',
     data : formdata
   };
          const res = await axios(config);  
          console.log(res.data.mtn)
          if(res.data.mtn.status === 1){
          

            try {

                var formdata1 = new FormData();
                    
                    formdata1.append('phonenumber', sing.phone);
                     formdata1.append('msg', `NEW PASSWORD : ${password}`);
                    
                     
             var config1 = {
                 method: 'post', 
                url: 'https://contactless.mtnonline.com/live/send_sms_update',
                 data : formdata1
               };
                      const res = await axios(config1);  
                      console.log(res.data.mtn)
                      if(res.data.mtn.status === 1){
                        
                        
                        toast.current?.show({severity:'success', summary: 'User update successful.', detail:'', life: 2500});
                        setLoadingReset(false);
                       
                      }
                      else{
                        toast.current?.show({severity:'error', summary: 'user update failed.', detail:'please try again', life: 2500});
                        setLoadingReset(false);
                      }
                
                    } catch (error) {
                        toast.current?.show({severity:'error', summary: 'password do not match.', detail:'please try again', life: 2500});
                setLoadingReset(false);
                     console.log(error)
                    }
           
          }
          else{
            toast.current?.show({severity:'error', summary: 'user update failed.', detail:'please try again', life: 2500});
            setLoadingReset(false);
          }
    
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'password do not match.', detail:'please try again', life: 2500});
    setLoadingReset(false);
         console.log(error)
        }
}
else{
    toast.current?.show({severity:'error', summary: 'password do not match.', detail:'please try again', life: 2500});
    setLoadingReset(false);
}
}


const purge=async()=>{
    setLoadingPurge(true);
    try {
    //     var formdata = new FormData();
    //   formdata.append('email', userInfo?.email);
var config = {
    method: 'get',
    url: `https://mtn-services.verxid.site/logout?email=${sing?.email}`,
  };
      const res = await axios(config);
  
const stat = res.data.mtn.status;
const statz = res.data.mtn;
    console.log(stat,statz)
      if(stat === 1){
        toast.current?.show({severity:'success', detail:res.data.mtn.message, life: 2500});
          setLoadingPurge(false);
        //   window.location.href=`/single-user/${id}`
      }

      else{
        toast.current?.show({severity:'error', detail:res.data.mtn.message, life: 2500});
        setLoadingPurge(false)
      }
           
    } catch (error) {
        toast.current?.show({severity:'error', detail:'please try again', life: 2500});
        setLoadingPurge(false);
     console.log(error)
    }  

}

useEffect(() => {
    initFilters1();
setLoading1(true);


    const getSing=async ()=>{
        const statz = await axios.get(`https://mtn-services.verxid.site/viewSingleUser?id=${id}`);
        console.log(statz.data.mtn[0])
    setSing(statz.data.mtn[0]);
    setLoading1(false)
    }
            getSing();
}, [id])
   


     
       

    


useEffect(() => {

    
        const getGroups=async ()=>{
            const grpz = await axios.get(`https://api.verxid.site/mtn/get-group`);
            console.log(grpz.data.mtn.roles);
            const newGroup= grpz.data.mtn.roles.map((it,i)=>{
                // return {id:it.id,name:it.group_name};
                if(userInfo.role!=='7' && it.id==='7'){
return null;
                }
                return {id:it.id,name:it.group_name};
            }).filter(Boolean)
            console.log(newGroup);
            setGroups(newGroup)
        }
                getGroups();
    
    }, [userInfo.role])


// console.log(selectedFinger)
// console.log(typeof(sing?.status))
if(loading1){
    return(
        <ProgressSpinner/>
    )
}
    return (
       
        <div className="grid">
             <div className="col-12 md:col-6">
             {
    sing?.face &&
            <div className="card">
                    
                    <img alt='' src={`data:image/png;base64,${
                  sing?.face
                    }`
                    } width="250" height='250'/>
            
            </div>
}
             </div>
             <div className="col-12 md:col-6">
             {
    sing?.document &&
            <div className="card">
                    <Image alt='' src={`data:image/png;base64,${
                  sing?.document
                    }`
                    } width="250" height='250' preview />
            
            </div>
}
             </div>
            <div className="col-12">






                <div className="card">
                    
<div className="surface-0">
    <div className="font-medium text-3xl text-900 mb-3">Single Agent</div>
    {/* <div className="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div> */}
    <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Firstname</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.firstname}</div>
          
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Lastname</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.lastname}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">NIN</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.nin}</div>
          
        </li>
      

        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium"> phone</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.phone}</div>
           
        </li>
        
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Email</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.email}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Shop name</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.shopname}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Division</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.division}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Status</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing.status==='0'?'Pending':sing.status ==='1'?'Approved':sing.status ==='2'?'Blacklisted':''}</div>
           
        </li>

        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Group name</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.groupname}</div>
           
        </li>
       
   <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Actions</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip template={<Button label="Purge Agent" className="p-button-secondary p-button-text" loading={loadingPurge} onClick={purge}/>} className="mr-2 my-2"/>
            <Chip template={<Button label="Approve Agent" className="p-button-secondary p-button-text" loading={loadingApprove} onClick={approval}/>} className="mr-2 my-2"/>
                
             <Chip template={<Button label="Blacklist Agent" className="p-button-info p-button-text" loading={loadingBlack} onClick={blacked} />} className="mr-2 my-2"/> 
             <Chip template={<Button label="Invalidate Agent" className="p-button-secondary p-button-text" loading={loadingValid} onClick={valid} />} className="mr-2 my-2"/> 
             <Chip template={<Button label="Update Password" className="p-button-success p-button-text"  onClick={onShow} />} className="mr-2"/> 
             <Chip template={<Button label="View Registration" className="p-button-info p-button-text" loading={loadingRegz}  onClick={getRegz} />} className="mr-2 my-2"/> 
             <Chip template={<Button label="View Sim Swap" className="p-button-info p-button-text" loading={loadingSwap}  onClick={getRegz2} />} className="mr-2 my-2"/> 
              <div>

<Dropdown  options={groups}  value={groupId}   onChange={onRoleChange} optionLabel="name" placeholder="Select group" />
<Chip template={<Button label="Upgrade User" className="p-button-danger p-button-text" loading={loadingUpd} onClick={updateAgent} />} className="mr-2 my-2"/>
</div> 
</div>
</li>
                    {/*  <Chip template={<Button label="Push to CLM" className="p-button-success p-button-text" loading={loadingClm} onClick={clm} />} className="mr-2"/>

                <Chip template={<Button label="Push OWC face" className="p-button-warning p-button-text" loading={loadingOwc} onClick={owc} />} className="mr-2 my-2"/>
                <Chip template={<Button label="Push OWC NIN face" className="p-button-info p-button-text" loading={loadingOwcNin} onClick={owcNin} />} className="mr-2 my-2"/>


                <Chip template={<Button label="Push OWC left hand" className="p-button-secondary p-button-text" loading={loadingOwcLeft} onClick={owcleft} />} className="mr-2 my-2"/>
                <Chip template={<Button label="Push OWC right hand" className="p-button-info p-button-text" loading={loadingOwcRight} onClick={owcright} />} className="mr-2 my-2"/>


                {
    sing?.consent_type ==='Consent_Form' && (
                <Chip template={<Button label="Push OWC Consent" className="p-button-danger p-button-text" loading={loadingDoc} onClick={doc} />} className="mr-2 my-2"/>
   )}
                <Chip template={<Button label="Push OWC Summary" className="p-button-primary p-button-text" loading={loadingSum} onClick={sum} />} className="mr-2 my-2"/>
{
    sing?.type ==='fx_new' && (
    <><Chip template={<Button label="Push OWC Passport" className="p-button-danger p-button-text" loading={loadingPass} onClick={pass} />} className="mr-2 my-2"/> 

               
               <Chip template={<Button label="Push OWC Visa" className="p-button-info p-button-text" loading={loadingVisa} onClick={visa} />} className="mr-2 my-2"/>
               </>
    )
            }
             
<div>

<Dropdown  options={cities}  value={selectedFinger} onChange={onCityChange} optionLabel="name" placeholder="Select finger" />
<Chip template={<Button label="Push OWC Finger" className="p-button-danger p-button-text" loading={loadingFin} onClick={fin} />} className="mr-2 my-2"/>
</div>
                  {/*<Chip template={<Button label="Push OWC Consent" className="p-button-danger p-button-text" loading={loadingDoc} onClick={doc} />} className="mr-2 my-2"/> 

            </div>
        </li> */}
    </ul>
</div>
    
                </div>
                <Toast ref={toast} position="top-center" />
                <Dialog header="Reset password" visible={displayResponsive} onHide={onHide}  style={{width: '30vw'}} breakpoints={{'960px': '75vw'}}  footer={renderFooter()}>
                <div className="login-content text-center flex flex-column ">

                {/* <div className="login-input-wrapper my-3 ">
                  <Password  placeholder="Password"  onChange={(e)=>setPassword(e.target.value)} toggleMask /> 
              </div>

                <div className="login-input-wrapper my-3 ">
                    <Password  placeholder="confirm password"  onChange={(e)=>setConfirmPassword(e.target.value)} toggleMask />   
                </div> */}
                <Button label="Generate password" icon="pi pi-lock" className='my-3' onClick={generatePassword} />
               {password && <p className=''>******</p>}

                <Button label="Reset" icon="pi pi-check" loading={isLoadingReset} onClick={handleReset} />
            </div>
                </Dialog>
            </div>

            { viewReg && regz &&
            <div className="col-12 ">
                    <div className="card">
                        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}

                        <DataTable
                            value={regz}
                            paginator
                            rows={5}
                            className="p-datatable-products"
                            // globalFilter={globalFilter}
                            emptyMessage="No record found."
                            filterDisplay="row"
                            // header={header}
                            header={header1}
                            filters={filters1}
                            responsiveLayout="scroll"
                            globalFilterFields={['type','firstname','lastname']}>

                            <Column field="msisdn"  header="msisdn"></Column>
                            <Column field="sim_serial" header="sim serial"></Column>
                            <Column field="firstname" header="firstname"></Column>
                            <Column field="lastname" header="lastname"></Column>
                            <Column field="nin" header="NIN"></Column>
                            <Column field="type" header="Type" filter filterElement={statusRowFilterTemplate}  />
                            <Column field="" header="Status" body={agentStatus}></Column>
                        </DataTable>
</div>
</div>
}

{ viewSwap && swapz &&
            <div className="col-12 ">
                    <div className="card">
                        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}

                        <DataTable
                            value={swapz}
                            paginator
                            rows={5}
                            className="p-datatable-products"
                            // globalFilter={globalFilter}
                            emptyMessage="No record found."
                            filterDisplay="row"
                            // header={header}
                            header={header2}
                            filters={filters2}
                            responsiveLayout="scroll"
                            globalFilterFields={['swap_type','firstname','lastname']}>
          
                            <Column field="msisdn"  header="msisdn"></Column>
                            <Column field="sim_serial" header="sim serial"></Column>
                            <Column field="firstname" header="firstname"></Column>
                            <Column field="lastname" header="lastname"></Column>
                            <Column field="nin" header="NIN"></Column>
                            <Column field="swap_type" header="Type" filter filterElement={statusRowFilterTemplate2}  />
                            <Column field="" header="Status" body={agentStatus}></Column>
                        </DataTable>
</div>
</div>
}
       
        </div>
    );
}





export default SingleUser;