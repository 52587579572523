import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import AppWrapper from './AppWrapper';
// import "react-toastify/dist/ReactToastify.css";
import store from "./redux/store";
import { Provider } from "react-redux";
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Provider store={store}>
	<BrowserRouter>
	
	<AppWrapper></AppWrapper>
	{/* <App/> */}
	
		
	</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
