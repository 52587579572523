import { configureStore } from "@reduxjs/toolkit";
// import headerReducer from "./headerSlice";
// import productReducer from "./productSlice";
// import cartReducer from "./cartSlice";
import userReducer from "./userSlice";
// import orderReducer from "./orderSlice";
// import orderDetailReducer from "./orderDetail";
// import summaryReducer from "./summarySlice";
export default configureStore({
  reducer: {
    // header: headerReducer,
    // product: productReducer,
    // cart: cartReducer,
    user: userReducer,
    // order: orderReducer,
    // orderDet: orderDetailReducer,
    // summary: summaryReducer,
  },
});
