import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    isLoading: false,
    error: false,
  },

  reducers: {
   
    loginStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error:false
      };
    },
    loginFailure: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error:true
      };
    },

    otpStart: (state) => {
      return {
        ...state,
       
      };
    },
    otpSuccess: (state, action) => {
      return {
        ...state,
       
        userInfo: action.payload,
      };
    },
    otpFailure: (state, action) => {
      return {
        ...state,
       
        error:true,
      };
    },



    logout: (state) => {
      return {
        ...state,
        userInfo: null,
        isLoading: false,
      };
    },
    // updateUserStart: (state) => {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // },
    // updateUserSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     token: action.payload.token,
    //     userInfo: action.payload.user,
    //   };
    // },
    // updateUserFailure: (state, action) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: true,
    //   };
    // },
  },
});

export const {
  registerStart,
  registerSuccess,
  registerFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  otpStart,
  otpSuccess,
  otpFailure,
  logout,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} = userSlice.actions;

export default userSlice.reducer;
