import React, { useEffect } from 'react';
import { Route, useLocation, withRouter,Redirect } from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { useSelector } from "react-redux";
const AppWrapper = () => {

    let location = useLocation();
    const { userInfo } = useSelector((state) => state.user);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    switch (location.pathname) {
        case '/login':
            return <Route path="/login" > {userInfo?<Redirect to='/'/>:<Login/>}</Route>
        
            case '/notfound':
            return <Route  path="" component={NotFound} />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);
