import React, { useState, useRef, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useHistory, useLocation,Redirect} from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
// import AppConfig from './AppConfig';
import AppMenu from './AppMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppInlineProfile from './AppInlineProfile';

import Dashboard from './components/Dashboard';

import TableDemo from './components/TableDemo';
import TableDemo2 from './components/TableDemo2';
import User from './pages/User';

import { Tooltip } from 'primereact/tooltip';
import { useSelector } from "react-redux";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { Switch } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Single from './pages/SingleSim';
import SingleUser from './pages/SingleUser';
import Thres from './pages/Thres';
import Devices from './pages/Device';
import Group from './pages/Group';

const App = () => {
    const { userInfo } = useSelector((state) => state.user);
    const [menuActive, setMenuActive] = useState(false);
    // eslint-disable-next-line
    const [menuMode, setMenuMode] = useState('horizontal');
    // eslint-disable-next-line
    const [darkMenu, setDarkMenu] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [inlineMenuActive, setInlineMenuActive] = useState(false);
    // eslint-disable-next-line
    const [profileMode, setProfileMode] = useState('popup');
    const [configActive, setConfigActive] = useState(false);
    // eslint-disable-next-line
    const [inputStyle, setInputStyle] = useState('outlined');
    // eslint-disable-next-line
    const [ripple, setRipple] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const history = useHistory();

    let menuClick = false;
    let configClick = false;
    let topbarItemClick = false;
    let inlineMenuClick = false;

    const menu = [
        {
            label: 'Home Page', icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }
            ]
        },
        {
            label: 'SIM', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Sim Registration', icon: 'pi pi-fw pi-id-card', to: '/sim-registrations' },
                { label: 'Sim Swap', icon: 'pi pi-fw pi-id-card', to: '/sim-swap' },
                // { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
                // { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
                // { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', to: '/invalidstate' },
                // { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button', class: 'rotated-icon' },
                // { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
                // { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
                // { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
                // { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
                // { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
                // { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
                // { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
                // { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
                // { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
                // { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
                // { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
            ]
        },

        {
            label: 'USER', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Onboard User', icon: 'pi pi-fw pi-id-card', to: '/user' },
                
            ]
        },

        {
            label: 'THRESHOLD', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Update Threshold', icon: 'pi pi-fw pi-id-card', to: '/threshold' },
                
            ]
        },

        {
            label: 'DEVICES', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'View Devices', icon: 'pi pi-fw pi-id-card', to: '/view-devices' },
                
            ]
        },

        {
            label: 'ADMIN GROUPS', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'View Groups', icon: 'pi pi-fw pi-id-card', to: '/groups' },
                
            ]
        },
        // {
        //     label: "PrimeBlocks", icon: "pi pi-prime",
        //     items: [
        //         { label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW", },
        //         { label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-react", target: "_blank" }
        //     ]
        // },
        // {
        //     label: 'Utilities', icon: 'pi pi-fw pi-compass',
        //     items: [
        //         { label: 'Icons', icon: 'pi pi-fw pi-prime', to: '/icons' },
        //         { label: "PrimeFlex", icon: "pi pi-fw pi-desktop", url: "https://www.primefaces.org/primeflex", target: "_blank" }
        //     ]
        // },
        // {
        //     label: 'Pages', icon: 'pi pi-fw pi-clone',
        //     items: [
        //         { label: 'Crud', icon: 'pi pi-fw pi-pencil', to: '/crud' },
        //         { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar' },
        //         { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
        //         { label: 'Landing', icon: 'pi pi-fw pi-user-plus', url: 'assets/pages/landing.html', target: '_blank' },
        //         { label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login' },
        //         { label: 'Invoice', icon: 'pi pi-fw pi-dollar', to: '/invoice' },
        //         { label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/help' },
        //         { label: 'Wizard', icon: 'pi pi-fw pi-star-fill', to: '/wizard' },
        //         { label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error' },
        //         { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound' },
        //         { label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access' },
        //         { label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
        //     ]
        // },
        // {
        //     label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
        //     items: [
        //         {
        //             label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
        //             items: [
        //                 {
        //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-align-left' }
        //                     ]
        //                 },
        //             ]
        //         },
        //         {
        //             label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
        //             items: [
        //                 {
        //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-align-left' },
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
        //                         { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-align-left' }
        //                     ]
        //                 },
        //             ]
        //         }
        //     ]
        // },
        // {
        //     label: 'Get Started', icon: 'pi pi-fw pi-download',
        //     items: [
        //         {
        //             label: 'Documentation', icon: 'pi pi-fw pi-file', to: '/documentation'
        //         },
        //         {
        //             label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
        //         }
        //     ]
        // }
    ];

    const routers = [
       
        { path: '/', component: <Dashboard/>, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },
       
        { path: '/sim-registrations', component: <TableDemo/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'All Registrations' }] } },
        { path: '/sim-swap', component: <TableDemo2/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'All sim swap'}]}},
        { path: '/user', component: <User/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'User' }] } },
        { path: '/threshold', component: <Thres/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'Thres' }] } },
        { path: '/view-devices', component: <Devices/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'Devices' }] } },
        { path: '/groups', component: <Group/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'Groups'}] } },
        { path: '/single-sim/:id', component: <Single/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'Single-registration' }] } },
        { path: '/single-user/:id', component: <SingleUser/>, meta: { breadcrumb: [{ parent: 'Pages', label: 'Single-user' }] } },
        // { path: '/empty', component: EmptyPage, meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] } },
       
        // { path: '/documentation', component: Documentation, meta: { breadcrumb: [{ parent: 'Pages', label: 'Documentation' }] } },
        { path: '*', component: <NotFound/>, meta: { breadcrumb: [{ parent: '', label: '' }] } },
        // { path: '*', component: Error, meta: { breadcrumb: [{ parent: 'Pages', label: 'Error Page' }] } },
    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    // const onInputStyleChange = (inputStyle) => {
    //     setInputStyle(inputStyle);
    // };

    // const onRippleChange = (e) => {
    //     PrimeReact.ripple = e.value;
    //     setRipple(e.value);
    // };

    // const onMenuModeChange = (e) => {
    //     setMenuMode(e.value);
    //     setStaticMenuDesktopInactive(false);
    //     setOverlayMenuActive(false);

    //     if (e.value === 'horizontal') {
    //         setProfileMode('popup');
    //     }
    // };

    // const onMenuColorChange = (e) => {
    //     setDarkMenu(e.value);
    // };

    // const onProfileChange = (e) => {
    //     setProfileMode(e.value);
    // };

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
            setInlineMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        inlineMenuClick = false;
        configClick = false;
        topbarItemClick = false;
        menuClick = false;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuClick = () => {
        menuClick = true;

        if (inlineMenuActive && !inlineMenuClick) {
            setInlineMenuActive(false);
        }
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static')
                return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        } else {
            return true;
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (isOverlay() && !isMobile()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }
        else {
            if (isDesktop()) {
                setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
            }
            else {
                setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            }
        }

        event.preventDefault();
    };

    const onProfileButtonClick = (event) => {
        setInlineMenuActive(prevInlineMenuActive => !prevInlineMenuActive);
        inlineMenuClick = true;

        if (isSlim() || isHorizontal()) {
            setMenuActive(false);
        }
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarItemClick = (event, item) => {
        topbarItemClick = true;

        if (activeTopbarItem === item) {
            setActiveTopbarItem(null);
        }
        else {
            setActiveTopbarItem(item);
        }

        event.preventDefault();
    };

    // const onConfigClick = () => {
    //     configClick = true;
    // };

    // const onConfigButtonClick = () => {
    //     setConfigActive(prevConfigActive => !prevConfigActive);
    //     configClick = true;
    // };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 896;
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isStatic = () => {
        return menuMode === 'static';
    };

    const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-static': isStatic(),
            'layout-overlay': isOverlay(),
            'layout-overlay-active': overlayMenuActive,
            'layout-horizontal': isHorizontal(),
            'layout-slim': isSlim(),
            'layout-static-inactive': staticMenuDesktopInactive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-menu-dark': darkMenu,
            'layout-menu-light': !darkMenu,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() });

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem} onMenuButtonClick={onMenuButtonClick} onTopbarMenuButtonClick={onTopbarMenuButtonClick} onTopbarItemClick={onTopbarItemClick}
                isHorizontal={isHorizontal()} profileMode={profileMode} isMobile={isMobile} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-logo">
                    <button className="p-link" onClick={() => history.push('/')}>
                    <img id="layout-topbar-logo" src="assets/layout/images/mtn/mtn-new-logo.svg" alt="mtn-logo" />
                    </button>
                </div>
                <div className="layout-menu-wrapper">
                    <div className="menu-scroll-content">
                        {hasInlineProfile && <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />}
                        <AppMenu model={menu} menuMode={menuMode} active={menuActive} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick} />
                    </div>
                </div>
            </div>

            <div className="layout-main">
                <AppBreadcrumb routers={routers} />

                <div className="layout-content">
                    <Switch>
                
                        {routers.map((router, index) => {
                        if (router.exact) {
                               return <Route  key={`router${index}`} path={router.path} 
                            //    component={ <Login /> } 
                               exact >
                                  {userInfo?router.component:<Redirect to='/login'/>}
                                   </Route> 
                            }
                    if(router.path ==='*'){
return <Route key={`router${index}`} path={router.path}> 
 {<Redirect to='/notfound'/>
}
</Route>
                    }     

                           return <Route  key={`router${index}`} path={router.path} >
                            {userInfo?router.component:<Redirect to='/login' />}
                           </Route>
})
}
                    </Switch>
                   
                </div>

                <AppFooter />
            </div>

            {/* <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange}
                isDarkMenu={darkMenu} onMenuColorChange={onMenuColorChange}
                profileMode={profileMode} onProfileChange={onProfileChange} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}></AppConfig> */}

            {staticMenuMobileActive && <div className="layout-mask"></div>}
        </div>
    );
}

export default App;
