import React ,{useState,useRef}from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { Password } from 'primereact/password';
// import { loginUser } from '../redux/apiCalls';
import {
 
    loginStart,
    loginSuccess,
    loginFailure,
    otpStart,
    otpSuccess,
    otpFailure,
   
  } from "../redux/userSlice";
  import axios from "axios";
//   import { toast } from "react-toastify";
  import { Toast } from 'primereact/toast';

const Login = () => {
    const { isLoading} = useSelector((state) => state.user);
const [username,setUsername] = useState('');
const [password,setPassword] = useState('');
const [otp,setOtp] = useState('');
const [otpLoad,setOtpLoad] = useState(false);
const [showOtp,setShowOtp] = useState(false);
const [owner,setOwner] = useState({});
const dispatch = useDispatch();
const toast = useRef(null);
const history = useHistory();
const handleSubmit=async ()=>{
const cur={username,password};

    dispatch(loginStart());
    try {
        var formdata = new FormData();
        
        formdata.append('username', cur.username);
        formdata.append('password', cur.password);

var config = {
    method: 'post',
    url: 'https://mtn-services.verxid.site/login-admin',
   
    data : formdata
  };
      const res = await axios(config);
      console.log(res)
      const stat = res.data.mtn.status;
      if(stat === 1){
          setShowOtp(true);
          setPassword('');
          setUsername('');
          const {firstname,lastname,email,phone,role} = res.data.mtn;
          const user={firstname,lastname,email,phone,role};
          setOwner(user);
          dispatch(loginSuccess());
          toast.current?.show({severity:'success', summary: 'Verify OTP.', detail:'Please verify OTP sent to your phone.', life: 1500});
      }

      else{
        toast.current?.show({severity:'error', summary: 'Error Login In.', detail:'Incorrect Login details', life: 1500});
          dispatch(loginFailure());
      }
    
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'Error Login In.', detail:'Please retry,', life: 1500});
      dispatch(loginFailure());
      
     
    }

}


const verOtp=async()=>{
    dispatch(otpStart());
    setOtpLoad(true);
      try {
            var formdata = new FormData();
        
            formdata.append('otp',otp );
            formdata.append('email', owner.email);
            
            var config = {
              method: 'post',
              url: 'https://mtn-services.verxid.site/verify_otp',
             
              data : formdata
            };
          const res = await axios(config);
          console.log(res)
          const stat = res.data.mtn.status;
          if(stat === 1){
            setOtpLoad(false);
            setShowOtp(false);

            dispatch(otpSuccess(owner));
            toast.current?.show({severity:'success', summary: 'OTP Verified.', detail:'OTP verified.Login in..', life: 1500});
            localStorage.setItem('user',JSON.stringify(owner))
            setTimeout(()=>{
               history.push('/')
            },2500)
        }
  
        else{
            setOtpLoad(false);
          toast.current?.show({severity:'error', summary: 'Error verifying OTP.', detail:'Incorrect OTP', life: 1500});
            dispatch(otpFailure());
        }
          } catch (error) {
            setOtpLoad(false);
            toast.current?.show({severity:'error', summary: 'Error Verifying.', detail:'please retry', life: 1500});
            dispatch(otpFailure());
          }

}
    return (
        <div className="login-body">

            <div className="login-panel"></div>

            <div className="login-content">
                <img src="assets/layout/images/mtn/mtn-new-logo.svg" alt="babylon-layout" />

                <h1><span>SIGN IN</span> TO MTN</h1>
                <p>Welcome, please use the form to sign-in.</p>

                <div className="login-input-wrapper">
                    <InputText placeholder="Username" onChange={(e)=>setUsername(e.target.value)}  />
                    <i className="pi pi-user"></i>
                </div>

                <div className="login-input-wrapper">
                    {/* <InputText placeholder="Password"  onChange={(e)=>setPassword(e.target.value)} /> */}
                    <Password  placeholder="Password" className='w-full' onChange={(e)=>setPassword(e.target.value)} toggleMask />
                    {/* <i className="pi pi-lock"></i> */}
                </div>
{
    showOtp &&
                <div className="login-input-wrapper">
                <div className="p-inputgroup">
                           
                            <InputText placeholder="Verify OTP" onChange={(e)=>setOtp(e.target.value)}/>
                            <Button label="Verify" icon="pi pi-check" loading={otpLoad} onClick={verOtp}/>
                        </div>
                        </div>
}
                <Button label="Sign In" icon="pi pi-check" loading={isLoading} onClick={handleSubmit} />
            </div>
            <Toast ref={toast} position="top-center" />
        </div>
    )
}

export default Login;
