import React, { useState, useEffect,useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import axios from "axios";
// import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Device = () => {
    const [loading1, setLoading1] = useState(true);
    const [loadingBlack, setLoadingBlack] = useState(false);
    const [loadingWhite, setLoadingWhite] = useState(false);


    const toast = useRef(null);
    const [products, setProducts] = useState([]);
  
    const blacked=async(id)=>{
        setLoadingBlack(true);
        try {
            var formdata = new FormData();
          formdata.append('deviceid', id);
    var config = {
        method: 'post',
        url: `https://mtn-services.verxid.site/blacklist-application`,
        data : formdata
      };
          const res = await axios(config);
        //   const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
          
    // console.log(res.data);
          const stat = res.data.mtn.status;
          console.log(stat)
          if(stat === 1){ 
              setLoadingBlack(false)
              toast.current?.show({severity:'success', summary: 'Device blacklisted.', detail:res.data.mtn.message, life: 2500});
          }
          else{
            toast.current?.show({severity:'error', summary: 'Device not blacklisted.', detail:res.data.mtn.message, life: 2500});
            setLoadingBlack(false)
          }
               
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'Device not blacklisted.', detail:'please try again', life: 2500});
            setLoadingBlack(false);
         console.log(error)
    
        }
    
        
    }


    const white=async(id)=>{
        setLoadingWhite(true);
        try {
            var formdata = new FormData();
          formdata.append('deviceid', id);
    var config = {
        method: 'post',
        url: `https://mtn-services.verxid.site/whitelist-application`,
        data : formdata
      };
          const res = await axios(config);
      
          const stat = res.data.mtn.status;
          console.log(stat)
          if(stat === 1){ 
            setLoadingWhite(false)
            toast.current?.show({severity:'success', summary: 'Device whitelisted.', detail:res.data.mtn.message, life: 2500});
          }
          else{
            toast.current?.show({severity:'error', summary: 'Device not whitelisted.', detail:res.data.mtn.message, life: 2500});
            setLoadingWhite(false)
          }
               
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'Device not whitelisted.', detail:'please try again', life: 2500});
            setLoadingWhite(false);
         console.log(error)
    
        }
    
        
    }




    useEffect(() => {
        const getDatz=async ()=>{
            const statz = await axios.get('https://mtn-services.verxid.site/get-devices');
            console.log(statz.data.mtn.devices)
       
        setProducts(statz.data.mtn.devices)
        setLoading1(false)
        }
                getDatz()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

   

    const statusBodyTemplate2 = (rowData) => {
        return <div>
        <Button  className={`btn btn-primary font-bold my-2 lg:my-0`} loading={loadingBlack} onClick={()=>blacked(rowData.deviceid)} >
Blacklist Device
        </Button>
        <Button className={`btn btn-primary font-bold md:ml-3 my-2 lg:my-0`} loading={loadingWhite} onClick={()=>white(rowData.deviceid)} >
Whitelist Device
        </Button>
        </div>

    }

    
    const header = (
        <div className="table-header-container">
           
          All Devices
        </div>
    );


    return (
        <div className="grid table-demo">
           
            <div className="col-12">
                <div className="card">
                    
                    <DataTable value={products} 
                    // expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    loading={loading1}
                     responsiveLayout="scroll"
                     paginator
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[5,10,50]}
                  
                        header={header}>
                       
                        <Column field="devicename" header="NAME"></Column>
                            <Column field="deviceid" header="ID"></Column>

                        <Column field="id" header="Action" body={statusBodyTemplate2} />
                    </DataTable>
                </div>
            </div>
            <Toast ref={toast} position="top-center" />
        
        </div>

    );
}



export default Device;
