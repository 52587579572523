import { logout } from "./userSlice";

export const addUserToLocalStorage = ({ user, token }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

export const logoutUser = (dispatch) => {
  dispatch(logout());
  localStorage.removeItem("user");
  localStorage.clear();
  window.location.href = "/login";
};